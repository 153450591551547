import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createWarningAlert, handleError} from "../../utils/app-utils";
import {UserData} from "../../types/user/UserData";
import {LoginData} from "../../types/user/LoginData";
import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {useNavigate, useSearchParams} from "react-router-dom";
import useDataStore from "../data-store/data-store";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux/slices/auth-slice";
import {AuthState} from "../../types/state/AuthState";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";
import {pageActions} from "../../redux/slices/page-slice";
import {projectsActions} from "../../redux/slices/projects-slice";
import {eventsActions} from "../../redux/slices/events-slice";

const useLogin = () => {

  const { getLabel: label } = useLabels();
  const { language }: AuthState = useSelector((state: any) => state.auth);

  const dataStore = useDataStore();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, hasError, fetchData } = useFetch();

  const [alertData, setAlertData] = useState<AlertData>(null);

  const initLogin = () => {
    const eventType = searchParams.get('eventType');
    if (eventType && eventType === 'logged_out') {
      const warningAlert: AlertData = createWarningAlert(label(labels.login.errors.LOGGED_OUT));
      setAlertData(warningAlert);
    }
  }

  const login = async (loginData: LoginData) => {
    const eventType: string = searchParams.get('eventType');
    if (eventType) {
      searchParams.delete('eventType');
    }

    const reqData = createPostRequest(loginData);
    const respData = await fetchData('/user/login', reqData, false);
    if (!respData) {
      return;
    }

    const alert: AlertData = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return;
    }

    // console.log(respData);
    const userData: UserData = {
      ...respData.user,
    };

    dispatch(authActions.login(userData));
    await dataStore.loadDataStore(userData);

    navigate('/health');
  }

  const logout = () => {
    dispatch(authActions.logout());
    dispatch(pageActions.clear());
    dispatch(projectsActions.clear());
    dispatch(eventsActions.clear());

    navigate('/login');
  }

  const changeLanguage = () => {
    const newLanguage = language === 'DE' ? 'EN' : 'DE';
    dispatch(authActions.initLanguage(newLanguage));
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    alertData: alertData,
    initLogin: initLogin,
    login: login,
    logout: logout,
    changeLanguage: changeLanguage,
  };

}

export default useLogin;