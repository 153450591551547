import {AuthState} from "../../types/state/AuthState";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import classes from "./HealthPage.module.scss";

const HealthPage = () => {
  const { isLoggedIn }: AuthState = useSelector((state: any) => state.auth);


  if (!isLoggedIn) {
    return;
  }

  const token = 'IQabkoQYUi6Pbp8aa1wPjKr65i7Sa8p7ZjPWL3af75tBZ5K5r1';

  return (
      <>
        <h3 className="mb-4">Gesundheit</h3>

        <p className="mb-2">Meditation (Achtsamkeit)</p>
        <audio className={`mb-4 ${classes.fullWidth}`} src={`https://content.webfieber.at/files/${token}/_Meditation Achtsamkeit.mp3`} controls loop></audio>

        <p className="mb-2">Metta Meditation</p>
        <audio className={`mb-4 ${classes.fullWidth}`} src={`https://content.webfieber.at/files/${token}/_Metta Meditation (Custom).m4a`} controls loop></audio>

        <p className="mb-2">Autogenes Training</p>
        <audio className={`mb-4 ${classes.fullWidth}`} src={`https://content.webfieber.at/files/${token}/_Autogenes_Training_LANG.mp3`} controls></audio>
      </>
  );
}

export default HealthPage;