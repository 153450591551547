import {createBrowserRouter} from "react-router-dom";
import ErrorPage from "../pages/errors/ErrorPage";
import RootLayout from "../pages/layout/RootLayout";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/login/LoginPage";
import AuthLayout from "../pages/layout/AuthLayout";
import AccountPage from "../pages/user/AccountPage";
import ProjectListPage from "../pages/project/ProjectListPage";
import TimeRecordListPage from "../pages/time-record/TimeRecordListPage";
import EventCalendarPage from "../pages/event/EventCalendarPage";
import HealthPage from "../pages/health/HealthPage";

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout/>,
    errorElement: <ErrorPage/>,

    children: [

      { path: '/login',
        element: <LoginPage />, },

      { path: '/',
        element: <AuthLayout />,

        children: [
          { path: '/home',
            element: <HomePage /> },

          { path: '/health',
            element: <HealthPage /> },

          { path: '/account',
            element: <AccountPage /> },

          { path: '/events',
            element: <EventCalendarPage /> },

          { path: '/events/:projectId',
            element: <EventCalendarPage /> },

          { path: '/times',
            element: <TimeRecordListPage /> },

          { path: '/projects',
            element: <ProjectListPage /> },
        ]
      },

    ],
  },
]);

export default appRouter;