const labels: any = {
  nav: {
    HOME: { de: 'Home', en: 'Home', },
    HEALTH: { de: 'Gesundheit', en: 'Health', },
    EVENTS: { de: 'Kalender', en: 'Calendar', },
    TIME_RECORDS: { de: 'Zeiten', en: 'Times', },
    PROJECTS: { de: 'Projekte', en: 'Projects', },
    ACCOUNT: { de: 'Account', en: 'Account', },
    SWITCH_LANGUAGE: { de: '{0}', en: '{0}'},
    LOGOUT: { de: 'Logout', en: 'Logout', },
  },
  actions: {
    ADD: { de: 'Hinzufügen', en: 'Add'},
    CANCEL: { de: 'Abbrechen', en: 'Cancel'},
    SAVE: { de: 'Speichern', en: 'Save'},
    DELETE: { de: 'Löschen', en: 'Delete' },
  },
  errors: {
    UNKNOWN_ERROR: { de: 'Es ist ein Fehler aufgetreten.', en: 'An error occurred.',},
  },
  // LOGIN
  login: {
    fields: {
      USERNAME: {de: 'Benutzername', en: 'Username'},
      USERNAME_INVALID: {de: 'Benutzername ungültig.', en: 'Username invalid.'},
      PASSWORD: {de: 'Passwort', en: 'Password'},
      PASSWORD_INVALID: {de: 'Passwort ungültig.', en: 'Password invalid.'},
    },
    titles: {
      LOGIN: {de: 'Login', en: 'Login'},
    },
    actions: {
      LOGIN: {de: 'Anmelden', en: 'Login'},
    },
    errors: {
      LOGGED_OUT: {
        de: 'Sie wurden ausgeloggt. Bitte erneut anmelden.',
        en: 'You\' have been logged out. Please log in again.'
      },
      INVALID_USERNAME_PASSWORD: {
        de: 'Ungültiger Benutzername oder Passwort.',
        en: 'Invalid username or password.',
      },
      USER_BLOCKED: {
        de: 'Benutzer wurde gesperrt.',
        en: 'User has been blocked.',
      },
    }
  },
  events: {
    fields: {
      ID: { de: 'ID', en: 'ID', },
      NAME: { de: 'Bezeichnung', en: 'Name', },
      NAME_INVALID: { de: 'Bezeichnung ungültig.', en: 'Name invalid.', },
      EVENT_DATE: { de: 'Datum', en: 'Date', },
      EVENT_DATE_INVALID: { de: 'Datum ungültig.', en: 'Date invalid.', },
      EVENT_START_TO: { de: 'Zeitraum', en: 'From - To', },
      EVENT_START: { de: 'Beginn', en: 'Start', },
      EVENT_START_INVALID: { de: 'Beginn ungültig.', en: 'Start invalid.', },
      EVENT_END: { de: 'Ende', en: 'End', },
      EVENT_END_INVALID: { de: 'Ende ungültig.', en: 'End invalid.', },
      EVENT_TYPE: { de: 'Typ', en: 'Type', },
      PROJECT: { de: 'Projekt', en: 'Project', },
    },
    titles: {
      EVENTS: {de: 'Kalender', en: 'Calendar'},
      EVENTS_HINT: {de: 'Bitte keine sensiblen Daten eintragen.'},
      EVENTS_LOADED: {de: 'Ereignisse werden geladen.'},
      NO_EVENTS_FOUND: {de: 'Keine Ereignisse gefunden.'},
      EVENT_LOADED: {de: 'Ereignis wird geladen.'},
      NO_EVENT_FOUND: {de: 'Kein Ereignis gefunden.'},
    },
    actions: {
      NEW_EVENT: { de: 'Neues Ereignis', en: 'New Event'},
      EDIT_EVENT: { de: 'Ereignis bearbeiten', en: 'Edit Event'},
      DELETE_EVENT: { de: 'Ereignis löschen', en: 'Delete Event'},
      REALLY_DELETE_EVENT: {
        de: 'Möchten Sie das Ereignis "{0}" wirklich löschen?',
        en: 'Do you really want to delete the event "{0}"?'},
    },
    alerts: {
      ADDED_SUCCESSFULLY: {
        de: 'Das Ereignis "{0}" wurde erfolgreich angelegt.',
        en: 'The event "{0}" has been added successfully.'
      },
      EDITED_SUCCESSFULLY: {
        de: 'Das Ereignis "{0}" wurde erfolgreich bearbeitet.',
        en: 'The event "{0}" has been edited successfully.'
      },
      DELETED_SUCCESSFULLY: {
        de: 'Das Ereignis "{0}" wurde erfolgreich gelöscht.',
        en: 'The event "{0}" has been deleted successfully.'
      },
    }
  },

  timeRecords: {
    fields: {
      ID: { de: 'ID', en: 'ID', },
      RECORD_DATE: { de: 'Datum', en: 'Date', },
      RECORD_DATE_INVALID: { de: 'Datum ungültig.', en: 'Date invalid.', },
      RECORD_FROM: { de: 'Von', en: 'From', },
      RECORD_FROM_INVALID: { de: 'Von ungültig.', en: 'From invalid.', },
      RECORD_TO: { de: 'Bis', en: 'To', },
      RECORD_TO_INVALID: { de: 'Bis ungültig.', en: 'To invalid.', },
      RECORD_DURATION: { de: 'Dauer', en: 'Duration', },
      EFFORT: { de: 'Leistung', en: 'Effort', },
      EFFORT_INVALID: { de: 'Leistung ungültig.', en: 'Effort invalid.', },
      PROJECT: { de: 'Projekt', en: 'Project', },
    },
    titles: {
      TIME_RECORDS: {de: 'Zeiten', en: 'Times'},
      TIME_RECORDS_LOADED: {de: 'Zeiten werden geladen.'},
      NO_TIME_RECORDS_FOUND: {de: 'Keine Zeiten gefunden.'},
      TIME_RECORD_LOADED: {de: 'Zeit wird geladen.'},
      NO_TIME_RECORD_FOUND: {de: 'Keine Zeit gefunden.'},
    },
    actions: {
      NEW_TIME_RECORD: { de: 'Neue Aufzeichung', en: 'New Time Record'},
      EDIT_TIME_RECORD: { de: 'Aufzeichung bearbeiten', en: 'Edit Time Record'},
      DELETE_TIME_RECORD: { de: 'Aufzeichung löschen', en: 'Delete Time Record'},
      REALLY_DELETE_TIME_RECORD: {
        de: 'Möchten Sie die Aufzeichnung "{0}" wirklich löschen?',
        en: 'Do you really want to delete the time record "{0}"?'},
    },
    alerts: {
      ADDED_SUCCESSFULLY: {
        de: 'Die Aufzeichnung "{0}" wurde erfolgreich angelegt.',
        en: 'The time record "{0}" has been added successfully.'
      },
      EDITED_SUCCESSFULLY: {
        de: 'Die Aufzeichnung "{0}" wurde erfolgreich bearbeitet.',
        en: 'The time record "{0}" has been edited successfully.'
      },
      DELETED_SUCCESSFULLY: {
        de: 'Die Aufzeichnung "{0}" wurde erfolgreich gelöscht.',
        en: 'The time record "{0}" has been deleted successfully.'
      },
    }
  },
  // PROJECTS
  projects: {
    fields: {
      ID: { de: 'ID', en: 'ID', },
      NAME: { de: 'Bezeichnung', en: 'Name', },
      NAME_INVALID: { de: 'Bezeichnung ungültig.', en: 'Name invalid.', },
      DESCRIPTION: { de: 'Beschreibung', en: 'Description', },
      DESCRIPTION_INVALID: { de: 'Beschreibung ungültig.', en: 'Description invalid.', },
      SORT_ORDER: { de: 'Reihenfolge', en: 'Sort order'},
      SORT_ORDER_INVALID: { de: 'Reihenfolge ungültig.', en: 'Sort order invalid.'},
      COLOR: { de: 'Farbe', en: 'Color', },
      TYPE: { de: 'Typ', en: 'Type', },
    },
    titles: {
      PROJECTS: {de: 'Projekte', en: 'Projects'},
      PROJECTS_LOADED: {de: 'Projekte werden geladen.'},
      NO_PROJECTS_FOUND: {de: 'Keine Projekte gefunden.'},
      PROJECT_LOADED: {de: 'Projekt wird geladen.'},
      NO_PROJECT_FOUND: {de: 'Kein Projekt gefunden.'},
    },
    actions: {
      NEW_PROJECT: { de: 'Neues Projekt', en: 'New Project'},
      EDIT_PROJECT: { de: 'Projekt bearbeiten', en: 'Edit Project'},
      DELETE_PROJECT: { de: 'Projekt löschen', en: 'Delete Project'},
      REALLY_DELETE_PROJECT: {
        de: 'Möchten Sie das Project "{0}" wirklich löschen?',
        en: 'Do you really want to delete the project "{0}"?'},
    },
    alerts: {
      ADDED_SUCCESSFULLY: {
        de: 'Das Projekt "{0}" wurde erfolgreich angelegt.',
        en: 'The project "{0}" has been added successfully.'
      },
      EDITED_SUCCESSFULLY: {
        de: 'Das Projekt "{0}" wurde erfolgreich bearbeitet.',
        en: 'The project "{0}" has been edited successfully.'
      },
      DELETED_SUCCESSFULLY: {
        de: 'Das Projekt "{0}" wurde erfolgreich gelöscht.',
        en: 'The project "{0}" has been deleted successfully.'
      },
    }

  }
}

export default labels;