import {AuthState} from "../../types/state/AuthState";
import {useSelector} from "react-redux";

const HomePage = () => {

  const { isLoggedIn }: AuthState = useSelector((state: any) => state.auth);

  if (!isLoggedIn) {
    return;
  }

  return (
      <>
        <div className="d-flex justify-content-between">
          <p className="lead">Organize everything!</p>
        </div>
      </>
  );
}

export default HomePage;