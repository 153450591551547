import LoginForm from "../../components/login/LoginForm";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Alert from "../../components/_general/Alert";
import {LoginData} from "../../types/user/LoginData";
import DefaultErrorAlert from "../../components/_general/DefaultErrorAlert";
import useLogin from "../../hooks/login/login";
import {useSelector} from "react-redux";
import {AuthState} from "../../types/state/AuthState";

const LoginPage = () => {

  const { isLoggedIn }: AuthState = useSelector((state: any) => state.auth);

  const navigate = useNavigate();

  const { isLoading, hasError,
    alertData,
    initLogin, login } = useLogin();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/health');
      return;
    }

    initLogin();
  }, []);

  if (isLoggedIn) {
    return;
  }

  return (
      <>
        {alertData &&
              <Alert alertData={alertData} />
        }
        {hasError &&
            <DefaultErrorAlert />
        }
        <LoginForm isLoading={isLoading}
                   onLogin={login} />
      </>
  );
};

export default LoginPage;