import MainNav from "../../components/_layout/MainNav";
import {Outlet, redirect, useLocation, useNavigate, useNavigation} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AuthState} from "../../types/state/AuthState";
import {useDispatch, useSelector} from "react-redux";
import useLogin from "../../hooks/login/login";
import Footer from "../../components/_layout/Footer";

const RootLayout = () => {

  const { isLoggedIn, userData, language }: AuthState = useSelector((state: any) => state.auth);

  const {
    logout,
    changeLanguage,
  } = useLogin();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      if (isLoggedIn) {
        navigate('/health');
      } else {
        navigate('/login');
      }
    };
  }, []);

  return (
      <>
        <header>
          <MainNav isLoggedIn={isLoggedIn} userData={userData} language={language}
                   onLogout={logout} onChangeLanguage={changeLanguage} />
        </header>

        <main className={`container mt-4`}>
          <Outlet />
        </main>

        <Footer />
      </>
  );
};

export default RootLayout;
